import React, { useCallback, useEffect, useRef, useState } from "react";
import InnerHeader from "./InnerHeader";
import "../assets/style/Blog.css";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { BlogList, recentBlogsData } from "./Api";
import { FaClock, FaSearch, FaUser } from "react-icons/fa";
import debounce from "lodash.debounce";
import Pagination from "./pagination/Pagination";
import { Loader } from "./Loader";


const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const Blog = () => {
    const [error, setError] = useState(null);
    const searchInputRef = useRef(null);
    const [searchKeyword, setSearchKeyword] = useState("");
    const [debounceSearch, setDebounceSearch] = useState("");
    const [blog, setBlog] = useState([]);
    const [currentPage, setCurrentPage] = useState();
    const [recentPostData, setRecentPostData] = useState();
    const [searchParams] = useSearchParams();

    const [isLoading, setIsLoading] = useState(false)

    const search = searchParams.get("search");
    const page = parseInt(searchParams.get("page") || 1);
    const limit = parseInt(searchParams.get("limit") || 10);
    const category = searchParams.get("category") || '';

    const navigate = useNavigate();

    const debouncedSearch = useCallback(
        debounce((value) => setDebounceSearch(value), 500),
        []
    );

    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearchKeyword(value);
        debouncedSearch(value);
        if (value) {
            navigate(`/blog?search=${encodeURIComponent(value)}&limit=${limit}&page=${page}&category=${category || ''}`);
        } else {
            navigate(`/blog?limit=${limit}&page=${page}&category=${category || ''}`);
        }
    };

    const handleClickCategory = (id) => {
        navigate(`/blog?limit=${limit}&page=${page}&category=${id}`);
    }

    const handlePageChange = (page) => {
        if (search) {
            navigate(`/blog?search=${encodeURIComponent(search)}&limit=${limit}&page=${page}&category=${category || ''}`);
        } else {
            navigate(`/blog?limit=${limit}&page=${page}&category=${category || ''}`);
        }
        setCurrentPage(page);
    };

    useEffect(() => {
        setCurrentPage(page);
    }, [page]);

    const BlogListFetch = async () => {
        try {
            setIsLoading(true);
            const response = await BlogList({ debounceSearch, page, limit, category });
            setBlog(response);
            setIsLoading(false);
        } catch (error) {
            setError(error);
            setIsLoading(false);
        }
    };

    const recentTrandingAndCategoriesBlogs = async () => {
        try {
            const response = await recentBlogsData();
            setRecentPostData(response?.data);
        } catch (error) {
            setError(error);
        }
    }

    useEffect(() => {
        BlogListFetch();
        recentTrandingAndCategoriesBlogs();
    }, []);

    useEffect(() => {
        BlogListFetch();
    }, [debounceSearch, category, page]);

    return (
        <>
            {
                isLoading ? <Loader /> : (
                    <>
                        <InnerHeader />
                        <section className="blog-banner-section d-flex align-items-center">
                            <div className="blog-overlay"></div>
                            <div className="container text-center banner-content">
                                <h2 className="blog-banner-title"> Blogs</h2>
                            </div>
                        </section>

                        <div className="container content-wrap">
                            <main className="main-posts-list">
                                <div className="col-lg-8 col-md-12 col-sm-12 mb-4">
                                    {blog?.data?.length > 0 ? blog?.data?.filter((item) => item.trending === 1)
                                        .sort((a, b) => new Date(b.date) - new Date(a.date))
                                        .map((item, index) => (
                                            <div className="" key={index}>
                                                <article className="article d-flex">
                                                    <div className="article-img-column">
                                                        <Link
                                                            to={`/blogdetail/${item.slug}`}
                                                        >
                                                            <img
                                                                className="article-img img-fluid"
                                                                src={`${item.photo}`}
                                                                alt={item.title}
                                                            />
                                                        </Link>
                                                    </div>
                                                    <div className="article-text-column">
                                                        <ul className="tab-list list-unstyled d-flex flex-wrap">
                                                            <li className="tab-item me-3">
                                                                <a className="tab-link" href="#0">
                                                                    {item.category_name}
                                                                </a>
                                                            </li>
                                                        </ul>
                                                        <h2 className="article-title">
                                                            <Link
                                                                to={`/blogdetail/${item.slug}`}
                                                                state={{
                                                                    title: item.title,
                                                                    author_name: item.author_name,
                                                                    date: item.date,
                                                                    image: `${item.photo}`,
                                                                    detailPhoto: `${item.detailphoto}`,
                                                                    description: item.description,
                                                                    keywords: item.keywords
                                                                }}
                                                                className="textdecoration-none text-black"
                                                            >
                                                                {item.title}
                                                            </Link>
                                                        </h2>

                                                        <div className="auth">
                                                            <time className="article-datetime" dateTime={item.date}>
                                                                <FaClock className="mr-2" />
                                                                {item.date}
                                                            </time>
                                                            <cite className="article-author">
                                                                <FaUser className="mr-2" />
                                                                {item.author_name}
                                                            </cite>
                                                        </div>
                                                        <div className="article-content">
                                                            <p
                                                                className="clamped-text mb-0"
                                                                dangerouslySetInnerHTML={{ __html: item.description }}
                                                            ></p>
                                                            <ul className="tab-list list-unstyled d-flex flex-wrap">
                                                                <li className="tab-item me-3">

                                                                    <Link
                                                                        to={`/blogdetail/${item.slug}`}
                                                                        state={{
                                                                            title: item.title,
                                                                            author_name: item.author_name,
                                                                            date: item.date,
                                                                            image: `${item.photo}`,
                                                                            detailPhoto: `${item.detailphoto}`,
                                                                            description: item.description,
                                                                            keywords: item.keywords
                                                                        }}
                                                                    >
                                                                        Read more...
                                                                    </Link>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </article>
                                            </div>
                                        )) : <p className="text-center"> No blogs data found !</p>
                                    }

                                    <div>
                                        <Pagination
                                            totalPages={blog?.data?.totalPages || 1}
                                            currentPage={currentPage}
                                            onPageChange={handlePageChange} />
                                    </div>
                                </div>

                                <aside className="sidebar col-lg-4 col-md-8 col-sm-12">
                                    <div className="search-box">
                                        <input type="search" placeholder="Search Blog" onChange={handleSearchChange} value={searchKeyword} />
                                        <div className="search-icon">
                                            <FaSearch />
                                        </div>
                                    </div>

                                    <section className="new-posts mb-4">
                                        <h2 className="sidebar-title">Recent Blogs</h2>
                                        <div className="new-posts-list">
                                            {recentPostData?.recentblog?.filter((item) => item.trending === 1)
                                                .sort((a, b) => new Date(b.date) - new Date(a.date))
                                                .map((item, index) => (
                                                    <article
                                                        className="new-posts-article d-flex mb-3"
                                                        key={index}
                                                    >
                                                        <div className="post-img-column">
                                                            <Link
                                                                to={`/blogdetail/${item.slug}`}
                                                                state={{
                                                                    title: item.title,
                                                                    author_name: item.author_name,
                                                                    date: item.date,
                                                                    image: `${item.photo}`,
                                                                    detailPhoto: `${item.detailphoto}`,
                                                                    description: item.description,
                                                                    keywords: item.keywords
                                                                }}
                                                            >
                                                                <img
                                                                    className="new-posts-image img-fluid"
                                                                    src={`${item.photo}`}
                                                                    alt={item.imgAlt}
                                                                    style={{
                                                                        borderRadius: "10px",
                                                                        height: "auto",
                                                                        maxWidth: "80px",
                                                                    }}
                                                                />
                                                            </Link>
                                                        </div>
                                                        <div className="post-text-column ms-3">
                                                            <h5 className="new-posts-title">
                                                                <Link
                                                                    to={`/blogdetail/${item.slug}`}
                                                                    state={{
                                                                        title: item.title,
                                                                        author_name: item.author_name,
                                                                        date: item.date,
                                                                        image: `${item.photo}`,
                                                                        detailPhoto: `${item.detailphoto}`,
                                                                        description: item.description,
                                                                    }}
                                                                    className="new-posts-link"
                                                                >
                                                                    {item.title}
                                                                </Link>
                                                            </h5>

                                                            <div className="auth">
                                                                <time
                                                                    className="article-datetime"
                                                                    dateTime={item.date}
                                                                >
                                                                    <FaClock className="mr-2" />
                                                                    {item.date}
                                                                </time>
                                                                <cite className="article-author">
                                                                    <FaUser className="mr-2" />
                                                                    {item.author_name}
                                                                </cite>
                                                            </div>
                                                        </div>
                                                    </article>
                                                ))}
                                        </div>
                                    </section>

                                    <section className="tags mb-4">
                                        <h2 className="sidebar-title">Trending</h2>
                                        <ul className="tags-list list-unstyled d-flex flex-wrap gap-2">
                                            {recentPostData?.trending?.filter((item) => item.trending === 1)
                                                .map((item, index) => (
                                                    <li className="tag-item me-2" key={index}>
                                                        <Link className="tag-link" to={`/blogdetail/${item.slug}`}>
                                                            {item.title}
                                                        </Link>
                                                    </li>
                                                ))}
                                        </ul>
                                    </section>

                                    <section className="categories">
                                        <h2 className="sidebar-title">Categories</h2>
                                        <ul className="categories-list list-unstyled">
                                            {recentPostData?.categories?.map((category, index) => (
                                                <li
                                                    className="category-item border-bottom pb-2 mb-2"
                                                    key={index}
                                                >
                                                    <p className="category-link" onClick={() => handleClickCategory(category?.id)} >
                                                        {category?.category_name}
                                                    </p>
                                                </li>
                                            ))}
                                        </ul>
                                    </section>
                                </aside>
                            </main>
                        </div>
                    </>
                )
            }

        </>
    );
};

export default Blog;
