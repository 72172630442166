import React from "react";
import "./pagination.css";


const Pagination = ({ totalPages, currentPage, onPageChange }) => {

  const handleClick = (page) => {
    if (page >= 1 && page <= totalPages) {
      onPageChange(page);
    }
  };

  const maxVisiblePages = 5;
  const startPage = Math.max(
    1,
    Math.min(
      currentPage - Math.floor(maxVisiblePages / 2),
      totalPages - maxVisiblePages + 1
    )
  );
  const endPage = Math.min(startPage + maxVisiblePages - 1, totalPages);

  const pages = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);

  return (
    <div className="pagination_rounded">
      <ul>
        {/* Previous Button */}
        <li>
          <a
            href="#"
            className={`prev ${currentPage === 1 ? "disabled" : ""}`}
            onClick={(e) => {
              e.preventDefault();
              handleClick(currentPage - 1);
            }}
          >
            <i className="fa fa-angle-left" aria-hidden="true"></i> Prev
          </a>
        </li>

        {/* Page Numbers */}
        {pages.map((page) => (
          <li key={page}>
            <a
              href="#"
              className={currentPage === page ? "active" : ""}
              onClick={(e) => {
                e.preventDefault();
                handleClick(page);
              }}
            >
              {page}
            </a>
          </li>
        ))}

        {/* Next Button */}
        <li>
          <a
            href="#"
            className={`next ${currentPage === totalPages ? "disabled" : ""}`}
            onClick={(e) => {
              e.preventDefault();
              handleClick(currentPage + 1);
            }}
          >
            Next<i className="fa fa-angle-right" aria-hidden="true"></i>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Pagination;
